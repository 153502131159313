<template>
  <!-- 频道配置 -->
  <div>
    <!-- 频道配置 -->
    <div class="flex">
      <div>
        <span style="width: 140px">频道名称</span>
        <el-input size="medium" v-model="form" placeholder="请输入频道名称"></el-input>
        <div>
          <el-button size="medium" @click="resetForm">重置</el-button>
          <el-button size="medium" type="primary">查询</el-button>
        </div>
      </div>
      <el-button size="medium" type="danger" @click="newNews">新增</el-button>
    </div>

    <!-- 中间表格区域 -->
    <el-table :data="supList" border>
      <el-table-column label="序号" prop="id" width="180"></el-table-column>
      <el-table-column label="频道名称" prop="remark" width="240"></el-table-column>
      <el-table-column label="频道描述" prop="sort" width="240"></el-table-column>
      <el-table-column label="操作时间" prop="updateTime" width="240"></el-table-column>
      <el-table-column label="操作人" prop="adminName" width="180"></el-table-column>
      <el-table-column label="操作" prop="time" width="">
        <template slot-scope="scope">
          <div class="operation">
            <div @click="goUpdt(scope.row.id, scope.$index)">查看</div>
            <div @click="goUpdtt(scope.row.id, scope.$index)">修改</div>
            <div @click="handleDelete(scope.row.id, scope.$index)">删除</div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[1, 2, 3, 4, 5]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      supList: [],
      form: '',
      pageSize: 10,
      time: '',
      totalItems: 0,
      currentPage4: 4
    }
  },
  created() {
    this.supportList()
  },
  methods: {
    // 获取所有频道
    supportList() {
      this.axios
        .get('/admin/channel/showChannel', {
          pageSize: 10,
          currentPage: 1
        })
        .then(res => {
          console.log(res)
          this.supList = res.data.data.records
        })
        .catch(err => {})
    },
    // 跳转更新页面
    goUpdtt(id) {
      this.$router.push(`tupdate?id=${id}`)
    },

    //   重置
    resetForm() {
      this.form = ''
    },
    // 删除
    handleDelete(id, index) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.axios.delete(`/admin/channel/delChannel?id=${id}`, {}).then(res => {
            if (res.data.code == 200) {
              // 删除元素
              this.supList.splice(index, 1)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
            } else {
              this.$message({
                type: 'errer',
                message: '删除失败!'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    },

    // 跳转频道配置新增
    newNews() {
      // alert("新增频道配置");
      this.$router.push('addsupport')
    },
    // 跳转查看页面
    goUpdt(id) {
      this.$router.push(`updtadd?id=${id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.flex {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    span {
      width: 80px;
    }
    div {
      margin: 0 10px;
    }
  }
}
.pagination {
  padding: 60px 0 0 110px;
}
.operation {
  display: flex;
  div {
    margin-left: 12px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
</style>
